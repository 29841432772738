/* Reset some default styles for the browser */
html, body, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
    display: block;
}
html {
    height: 100%; /* Ensuring html takes up the full height */
}

body {
    line-height: 1;
    margin: 0;
    padding: 0;
    /* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;  */

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
    /* Linear gradient from top to bottom, from a light beige to a slightly darker shade. */
    background: linear-gradient(to bottom, #f3b03b, #836251);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    color: #444; /* Adjusted dark text color for better readability against the new background */
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Universal box-sizing */
*, *:before, *:after {
    box-sizing: border-box;
}

/* Simple and clean link styles */
a {
    color: #3498db; /* Change this color to match your theme */
    text-decoration: none;
}

a:hover {
    color: #2980b9; /* Darken the color slightly on hover */
    text-decoration: underline;
}

/* Style headings to make them stand out */
h1, h2, h3 {
    color: #333; /* Dark text color for headings */
    /* font-family: 'Segoe UI', 'Helvetica Neue', sans-serif; Modern sans-serif font for headings */
    font-family: 'Tilt Neon', sans-serif !important;
    margin-bottom: 0.5em;
}

h4, h5, h6 {
    color: #333; /* Dark text color for headings */
    /* font-family: 'Segoe UI', 'Helvetica Neue', sans-serif; Modern sans-serif font for headings */
    /* font-family: 'Tilt Neon', sans-serif !important; */
    margin-bottom: 0.5em;
}

h1 {
    font-size: 2.5em !important;  /* Main title, should be the largest */
}

h2 {
    font-size: 2em !important;  /* Sub-sections, slightly smaller */
}

h3 {
    font-size: 1.75em !important;  /* Smaller subsections or highlighted content */
}

h4 {
    font-size: 1.5em !important;  /* Other subsections or category titles */
}

h5 {
    font-size: 1.25em !important;  /* Minor headings */
}

h6 {
    font-size: 1em !important;  /* Smallest, for less important headings or meta info */
}
/* Add some padding around all elements */
body > * {
    padding: 0px;
}
